export const setHeaderSize = (size) => ({
  type: "SET_HEADER_SIZE",
  data: size,
});

export const setOnScroll = () => ({
  type: "on_scroll",
});

export const setFooterSize = (size) => ({
  type: "SET_FOOTER_SIZE",
  data: size,
});
