export const url = {
  home: "/",
  contact: "/contact",
  commercial: "/commercial",
  about: "/about",
  residential_inner: "/residential_inner",
  residential: "/residential",
  commercial_inner: "/commercial_inner",
  kalatirth_premium: "/kalatirth_premium",
  kala_dham: "/kala_dham",
  formss: "/Formss",
  kalasagar_skies: "/Kalasagarskies",
  kalasagar_skyz: "/KalasagarSkyz",
  kala_kunj: "/Kalakunj",
  kala_Square: "/Kalasquare",
  kala_Samartheshwar: "/Kalasamartheshwar",
};
